import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {  AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConferenceListComponent } from './conference-list/conference-list.component';
import { HttpClientModule } from '@angular/common/http';
import { DatafilterPipe } from './shared/datafilter.pipe';
import { SearchdataPipe } from './shared/searchdata.pipe';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { environment } from '../environments/environment';
import config from './app.config';
import { APP_BASE_HREF } from '@angular/common';

const oktaAuth = new OktaAuth(config.oidc);

@NgModule({
   declarations: [
      AppComponent,
      ConferenceListComponent,
    DatafilterPipe,
    SearchdataPipe
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      OktaAuthModule,
   ],
   providers: [
      { 
         provide: OKTA_CONFIG, 
         useFactory: () => {
           const oktaAuth = new OktaAuth(config.oidc);
           return {
             oktaAuth,
             onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
               const triggerLogin = async () => {
                 await oktaAuth.signInWithRedirect({ originalUri: '/' });
               };
               if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                 triggerLogin();
               } 
             }  
           }
         }
       },
       //{ provide: OKTA_CONFIG, useValue: { oktaAuth } },
       { provide: APP_BASE_HREF, useValue: environment.appBaseHref }
   ],
   bootstrap: [
      AppComponent
   ],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
