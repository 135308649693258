<!--The content below is only a placeholder and can be replaced.-->

<div class="container-fluid no-gutters">
  
  <div class="container">
      <div class="row header" >
        
        <div class="col-sm focusbars tlstr__left" >
      <h1>{{title}}</h1>
        </div>
        <div class="col-sm  tlstr__right">
           <!--  <p>Experience Gartner's market-leading insights and peer networking</p> -->
        </div>


    </div>
  </div>
</div>
<!-- <app-conference-list></app-conference-list> -->
<router-outlet></router-outlet>

