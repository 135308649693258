import { environment } from '../environments/environment';

export default {
  oidc: {
    clientId: environment.clientId,
    issuer:environment.issuer,
    redirectUri: '/signin-oidc',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    // testing: {
    //   disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`
    // }
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
