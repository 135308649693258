import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchdata'
})
export class SearchdataPipe implements PipeTransform {

  transform(items: any, filter: any, isAnd:boolean): any {
    if (filter && Array.isArray(items)) {
     let filterKeys = Object.keys(filter);
      if (isAnd) {
       return items.filter(item =>
          filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true));
              console.log('hit1')
      } else {
       return items.filter(item => {
        return filterKeys.some((keyName) => {
         // console.log(keyName);
      //   console.log('hit2')
          return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";
        });
      });
     }
    } else {
     // console.log(items)
     return items;
    }
  }

}
