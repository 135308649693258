import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ConferenceListService } from './conference-list.service';
import { formatDate } from "@angular/common";
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-conference-list',
  templateUrl: './conference-list.component.html',
  styleUrls: ['./conference-list.component.css']
})
export class ConferenceListComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  conferencesList: any[];
  tempconferencesList: any[];
  conferenceFilter: any;
  regions: any;
  conferenceTypes: any;
  searchString: any;
  isConferencesHidden = true;
  formattedConferenceList: any[] = [];
  isAuthenticated: boolean = false;

  constructor(private _conferenceService: ConferenceListService,
    public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

  getConferences = async function () {
    let token = await this.oktaAuth.getAccessToken();
    this.subscription = this._conferenceService.getAllConferences(token).subscribe(
      {
        // on successful emissions
        next: allfields => {
          this.conferencesList = allfields.value.conferenceList;
          // filter out old conferences by conference end date and EnableRegister
          function removeOldConferences(value) {
            var today = new Date();
            // format date and time
            let confEndDate = new Date(value.EventEndDateTime);
            let dateToday = new Date(today);
            let enableRegister = value.EnableRegister;
            // Return conferences if endate is > today and enableRegister is === true and filter out the rest.
            if (confEndDate >= dateToday && enableRegister === true) {
              return true;
            }
          }
          // Filter out old conferences
          this.conferencesList = this.conferencesList.filter(removeOldConferences);
          // Format eventdate into new Key value pair
          let tempConflist = {};
          for (let i = 0; i < this.conferencesList.length; i++) {
            let tempStartDate = formatDate(this.conferencesList[i].EventStartDateTime, 'MMM', 'en-US');
            let tempEndDate = formatDate(this.conferencesList[i].EventEndDateTime, 'MMM', 'en-US');

            if (tempStartDate === tempEndDate) {
              let eventdate = { ConferenceDate: formatDate(this.conferencesList[i].EventStartDateTime, 'dd', 'en-US') + ' - ' + formatDate(this.conferencesList[i].EventEndDateTime, 'dd MMMM yyyy', 'en-US') };
              tempConflist = { ...this.conferencesList[i], ...eventdate };
              this.formattedConferenceList.push(tempConflist);
            }
            else {
              let eventdate = { ConferenceDate: formatDate(this.conferencesList[i].EventStartDateTime, 'dd MMMM', 'en-US') + ' - ' + formatDate(this.conferencesList[i].EventEndDateTime, 'dd MMMM yyyy', 'en-US') };
              tempConflist = { ...this.conferencesList[i], ...eventdate };
              this.formattedConferenceList.push(tempConflist);
            }
          }
          //this.conferencesList =this.conferencesList;
          // console.log("FormattedConferenceList",this.formattedConferenceList)
          this.conferencesList = this.conferencesList.sort((a, b) => new Date(a.EventStartDateTime).getTime() - new Date(b.EventStartDateTime).getTime());
          //this.conferencesList = this.conferencesList;
          console.log("conferencesList", this.conferencesList)
        },
        // on errors
        error: error => {
          this.errorMessage = <any>error;
        },

        // called once on completion
        complete: () => {
          /* Create a regions list for filters */
          /*           const regionList = [];
                    const regionmap = new Map();
                    for (const item of this.conferencesList) {
                      if (!regionmap.has(item.Region)) {
                        regionmap.set(item.Region, true);    // set any value to Map
                        regionList.push({
                          Region: item.Region
                        });
          
                      }
                    }
          
          
                    const ConferenceTypeList = [];
                    const ConferenceTypemap = new Map();
                    for (const item of this.conferencesList) {
                      if (!ConferenceTypemap.has(item.Conference)) {
                        ConferenceTypemap.set(item.Conference, true);    // set any value to Map
                        ConferenceTypeList.push({
                          ConferenceType: item.Conference
                        });
          
                      }
                    }
          
          
                    const RolesLists = [];
                    const RolesMap = new Map();
                    for (const item of this.conferencesList) {
                      if (!RolesMap.has(item.Role)) {
                        //RolesLists   console.log(item.Role);
                        for (let i = 0; item.Role.length > i; i++) {
                          RolesMap.set(item.Role[i].name, true);
                          RolesLists.push({
                            name: item.Role[i].name,
                            id: item.Role[i].id
                          });
                        }
                      }
                    }
          
                    const roles = [];
                    const map = new Map();
                    for (const item of RolesLists) {
                      if (!map.has(item.id)) {
                        map.set(item.id, true);    // set any value to Map
                        roles.push({
                          id: item.id,
                          name: item.name
                        });
                      }
                    }
                    //  console.log(roles)
          
                    this.regions = regionList;
                    this.conferenceTypes = ConferenceTypeList;
                    this.roles = roles; */

        }// end of complete
      }
    );

  }
  async affermation() {
    this.isConferencesHidden = false;
    await this.getConferences();
  }
 ngOnInit() {

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
