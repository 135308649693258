import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ConferenceListComponent } from './conference-list/conference-list.component';


const routes: Routes = [
  {
    path:'',
    component: ConferenceListComponent,
    canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'signin-oidc',
    component: OktaCallbackComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
