import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})

// https://tutorialedge.net/typescript/angular/angular-query-params-tutorial/
//https://stackoverflow.com/questions/48817781/overload-request-headers-and-params-with-httpclient-get
export class ConferenceListService {
  env = environment;

  private _conferencesApiUrl = this.env.apiUrl;
constructor(private _http: HttpClient) { }

public getAllConferences(token: string): Observable<any> {
  
  // console.log('server: ' + this._server);
  // console.log('newArray: '+ JSON.stringify(newArray));
   // Call the http post
   return this._http.get(this._conferencesApiUrl, {
   //return this._http.post<any>(this._controlsUrl, newArray, {
     headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': 'https://gaw.internal.emtqa.aws.gartner.com',
       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
       'Access-Control-Allow-Methods' :'GET,OPTIONS',
       'X-Api-Key':'95f7a635-344e-4898-92c4-f0ab6d63a248',
       'Authorization': token
     })
   }).pipe(
     map((response: Response) => response = response),
    // tap(data => console.log('All: ' + JSON.stringify(data))),
     catchError(this.handleError)
   );
 }

 private handleError(error: HttpErrorResponse) {
   if (error.error instanceof ErrorEvent) {
     // A client-side or network error occurred. Handle it accordingly.
     console.error('An error occurred:', error.error.message);
   } else {
     // The backend returned an unsuccessful response code.

     console.error(
       `Backend returned code ${error.status}, ` +
       `Message was:` + JSON.stringify(error.message));
   }
   // return an observable with a user-facing error message
   return throwError(
     {
       serverError:{
         errorCode:error.status,
         errorMessage:error.message
       }
     }
     );
 };

}
