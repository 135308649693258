<div class="container">
  <div class="form-group">
    <div style="margin:0 15px 15px 0px; font-size: 13px" *ngIf="!isConferencesHidden" id="heading-1">
      <h6 class="alert-heading" style="color: #002856">Ready to register?</h6>You can use the search box below, or scroll down the page, to select the conference you wish to attend. To ensure a successful registration, associates must register using their gartner.com email address as it appears in the associate directory.

    </div>
    <div style="margin:0 15px 15px 0px; font-size: 13px" *ngIf="!isConferencesHidden" id="heading-2">
      <h6 class="alert-heading" style="color: #002856">Don't see the conference you are looking for?</h6>Below are the Gartner conferences currently open for associate registration. If the conference you’re looking for isn’t listed, please check back later.
<!--       <br />*Associate Registration for the CIO Forums is by invitation only. Any associate that arrives onsite that is
      not on the invitation list will not be granted access to the conference. -->
    </div>
    <input id="search" *ngIf="!isConferencesHidden" type="text" class='form-control'
      placeholder="Search by conference name, date, location or code..." [(ngModel)]="searchString">
  </div>
</div>
<div class="container">
  <div class="alert alert-danger alert-dismissible fade show" role="alert">

    <p id="alert-1">Before registering please be sure that you have reviewed the <a
        href="https://inside.gartner.com/Interact/Pages/Content/Document.aspx?id=5717" target="_blank">Gartner
        Associate
        Registration & Travel Policy for Conference Attendance</a> and are qualified to attend.</p>
    <hr>
    <p id="alert-2" class="mb-0">All associate registrations are subject to review by leadership and should an
      associate not meet the requirements for attendance their registration may be
      canceled.
    </p>
    <br>
    <button id="alert-3" type="button" class="btn-sm btn btn-secondary" (click)="affermation()" data-dismiss="alert"
      aria-label="Close">Ok, let’s register</button>
  </div>
</div>
<section *ngIf="!isConferencesHidden" class="conferences">
  <div class="container">
    <ul class="list-group" style="padding-bottom: 30px;">
      <ng-container
        *ngFor="let item of conferencesList | searchdata: {EventCode: searchString, EventTitle: searchString, EventLocation: searchString, EventDate: searchString, Conference: searchString}:false; index as i">
        <li class="list-group-item" *ngIf="item.EnableRegister">
          <div class="row justify-content-md-center">
            <div class="col-sm-10">
              <!--     <div>{{item.EventCode}}</div>  -->
              <div class="title" [innerHTML]='item.EventTitle'></div>
              <div class="date"> {{item.EventDate}}</div>
              <div class="location">{{item.EventLocation}} <small class="eventcode">{{item.EventCode}}</small></div>
            </div>
            <div class="col-sm-2" style="text-align: right; margin-top:20px;">
              <a *ngIf="item.RegistrationLink" href="{{item.RegistrationLink}}" target="_blank" class="btn btn-success"
                style="min-width: 100px;">Register</a>
               <!-- <a *ngIf="item.RegistrationLink"
              href="https://www.gartner.com/login/loginInitAction.do?method=initialize&TARGET=https://www.gartner.com%2Ftechnology%2Fuser%2Fregistration%2FeventsregconfirmLogin.do%3FeventCode%3D{{item.EventCode}}%26vendorCode%3DRFR%26regType%3DATTENDEE%26cmpgn%3D%26prtyCd%3DSTAFF%26finishUrl%3Dhttp%253A%252F%252Fevents.gartner.com/go/{{item.EventCode}}"
              target="_blank" class="btn btn-success" style="min-width: 100px;">Register</a> -->
              <button *ngIf="!item.RegistrationLink" type="button" class="btn btn-outline-secondary" disabled
                style="min-width: 100px;"> Closed </button>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

</section>
